import * as React from 'react';
import {
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import Seo from '../components/seo';
import Layout from '../components/layout';
import PageHeader from '../components/PageHeader';
import { useTranslation } from 'react-i18next';

const ContactPage = () => {
  const { t } = useTranslation('contact');
  return (
    <Layout pageTitle="Contact Us">
      <Seo title={t('title')} />
      <PageHeader title={t('title')} description={t('description')}>
        <div className="pb-16 bg-white overflow-hidden lg:pb-24">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-20 lg:items-center">
              <div className="mb-10 -mx-4 relative lg:mb-0" aria-hidden="true">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.6635206104943!2d121.4741580753758!3d25.011546977830946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34680117af851a89%3A0xc8af66b7ae2090cf!2z5pm66Zu757O757Wx6IKh5Lu95pyJ6ZmQ5YWs5Y-4!5e0!3m2!1szh-TW!2stw!4v1713151349257!5m2!1szh-TW!2stw"
                  title="Google Map 智電系統股份有限公司"
                  width="600"
                  height="450"
                  style={{ border: '0' }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="relative">
                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl font-display">
                  {t('name')}
                </h3>
                <p className="block text-xl font-medium text-gray-500 pointer-events-none font-display">
                  {t('branch')}
                </p>
                <p className="flex mt-3 text-lg text-gray-500 hover:text-amber-400">
                  <PhoneIcon className="h-6 w-6 mx-2" />
                  <span className="text-gray-500">{t('phone')}</span>
                </p>
                <p className="flex mt-3 text-lg text-gray-500 hover:text-amber-400">
                  <EnvelopeIcon className="h-6 w-6 mx-2" />
                  <span className="text-gray-500">{t('email')}</span>
                </p>
                <p className="flex mt-3 text-lg text-gray-500 hover:text-amber-400">
                  <a
                    className="flex"
                    href="https://www.google.com/maps/?q=智電系統股份有限公司"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MapPinIcon className="h-6 w-6 mx-2" />
                    <span className="text-gray-500">{t('address')}</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </PageHeader>
    </Layout>
  );
};

export default ContactPage;
